import gql from 'graphql-tag';

export const LabelsQuery = gql`
	query LabelsQuery($contentId: ID!, $refetchQuery: Boolean!, $status: [String]) {
		content(id: $contentId, status: $status) {
			nodes {
				id
				type
				operations @skip(if: $refetchQuery) {
					operation
					targetType
				}
				space {
					id
				}
				labels(orderBy: { sortField: LABELLING_CREATIONDATE, direction: ASCENDING }) {
					count
					nodes {
						id
						label
						name
						prefix
					}
				}
			}
		}
	}
`;
