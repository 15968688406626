import gql from 'graphql-tag';

export const AddLabelsMutation = gql`
	mutation AddLabelsMutation($contentId: ID!, $labels: [LabelInput!]!) {
		addLabels(input: { contentId: $contentId, labels: $labels }) {
			labels {
				count
				nodes {
					id
					prefix
					name
					label
				}
			}
		}
	}
`;
