import gql from 'graphql-tag';

export const RecommendedLabelsQuery = gql`
	query RecommendedLabelsQuery($contentId: ID!, $spaceId: ID!) {
		getRecommendedLabels(entityId: $contentId, entityType: "page", spaceId: $spaceId) {
			recommendedLabels {
				id
				name
				namespace
				strategy
			}
		}
	}
`;
