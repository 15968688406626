import gql from 'graphql-tag';

export const DeleteLabelMutation = gql`
	mutation DeleteLabelMutation($contentId: ID!, $label: String!) {
		deleteLabel(input: { contentId: $contentId, label: $label }) {
			contentId
			label
		}
	}
`;
