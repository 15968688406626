import gql from 'graphql-tag';

export const LabelSearchQuery = gql`
	query LabelSearchQuery($contentId: ID!, $searchText: String!) {
		contentLabelSearch(contentId: $contentId, searchText: $searchText) {
			suggestedLabels {
				prefix
				name
				id
				label
			}
			otherLabels {
				prefix
				name
				id
				label
			}
		}
	}
`;
