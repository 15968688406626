import first from 'lodash/first';

// Regular expression to parse error message key and its arguments.
// Here is exmaple of error string which is returned from server side
// Ex: "com.atlassian.confluence.core.service.NotValidException: [ValidationError [messageKey=label.contains.invalid.chars, args=[a:b, 1, 2, 3]]]"
// `(?:\w|\.)+` : is a non-capturing group - to match with message key value
// `{1}` - exactly 1 time
// `\s*` : match any white space character
const re = /\[messageKey=((?:\w|\.)+){1},\s*args=\[((?:.)+)?\]\]\]/;

export function parseErrorMessage(errorMsgObject) {
	let message = '';
	let messageKey = '';
	const args = [];

	if (typeof errorMsgObject === 'object' && errorMsgObject.message) {
		message = errorMsgObject.message;
	}

	// try to get error key and its arguments from error message string
	if (typeof message === 'string' && message.indexOf('messageKey') >= 0) {
		if (re.test(message)) {
			const results = re.exec(message);
			// first element of array which is returned from `re.exec` is ful string of charecter matched.
			// Ex: if message="com.atlassian.confluence.core.service.NotValidException: [ValidationError [messageKey=label.contains.invalid.chars, args=[a:b, 1, 2, 3]]]"
			// results[0] = "[ValidationError [messageKey=label.contains.invalid.chars, args=[a:b, 1, 2, 3]]]"
			// Full detail: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/exec
			messageKey = results[1];

			// Basing on all error messages patterns, there are maximum of 2 arguments for message.
			const argArray = results[2] ? results[2].split(',') : [];
			if (argArray) {
				args[0] = first(argArray);
				args[1] = argArray.slice(1).join(', ');
			}
		}
	}

	return {
		message,
		messageKey,
		args,
	};
}
