import type { FC } from 'react';
import React, { forwardRef, Children, cloneElement, useCallback } from 'react';
import { styled } from '@compiled/react';
import { useIntl, defineMessages, FormattedMessage } from 'react-intl-next';
import type { MultiValueGenericProps } from 'react-select';

import type {
	OptionType,
	StylesConfig,
	ValueContainerProps,
	InputProps,
	ControlProps,
	SelectProps,
	OptionProps,
} from '@atlaskit/select';
import { token } from '@atlaskit/tokens';
import { components, mergeStyles } from '@atlaskit/select';
const { ValueContainer, Input, Control, MultiValueContainer, MultiValueLabel, Option } = components;
import CreatableSelect from '@atlaskit/select/CreatableSelect';
import { N800, N200, N20, N50, R75 } from '@atlaskit/theme/colors';
import { h300 } from '@atlaskit/theme/typography';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Tooltip from '@atlaskit/tooltip';
import EditorAddIcon from '@atlaskit/icon/core/migration/add--editor-add';

import { LoadableLazy } from '@confluence/loadable';
import { ExperienceSuccess } from '@confluence/experience-tracker';
import { PageSegmentLoadEnd } from '@confluence/browser-metrics';

import { getPrivacySafePrefix } from './useLabelsSelectProps';
import { LabelsSelectPlaceholder } from './ViewPageLabelsLoader';
import { VIEW_PAGE_LABELS_METRIC } from './perf.config';

const LabelsQueryErrorDisplayLoader = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-LabelsSelectErrors" */ './LabelsSelectErrors'))
			.LabelsQueryErrorDisplay,
});
const LabelsMutationErrorDisplayLoader = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-LabelsSelectErrors" */ './LabelsSelectErrors'))
			.LabelsMutationErrorDisplay,
});
const LabelsValidationErrorLoader = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-LabelsSelectErrors" */ './LabelsSelectErrors'))
			.LabelsValidationError,
});

const i18n = defineMessages({
	inputPlaceholder: {
		id: 'labels.labels-select.input-placeholder',
		defaultMessage: 'Search or create a label',
		description:
			'Placeholder for html input element that allows users to search or create a new label in the labels selector.',
	},
	createNewLabel: {
		id: 'labels.labels-select.create-new-label',
		defaultMessage: 'Create new label',
		description:
			'Text that appears in labels select before a label. Clicking on the item creates a new label for the page.',
	},
	addLabelPlaceholder: {
		id: 'labels.view-page-labels.placeholder',
		defaultMessage: 'Add label',
		description:
			'Placeholder text that appears in the labels text on view page. Upon clicking near this text in the labels select field, the labels selector will open.',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LabelSelectOption = styled.span({
	display: 'inline-block',
	fontWeight: 400,
	fontSize: token('font.body', '14px'),
	lineHeight: '20px',
	padding: `0 ${token('space.050', '4px')}`,
	borderRadius: '3px',
	backgroundColor: token('color.background.inverse.subtle', N20),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'.labels__option--is-focused &': {
		backgroundColor: token('color.background.inverse.subtle.pressed', N50),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CreateLabelContainer = styled.div({
	display: 'flex',
	gap: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CreateLabelText = styled.div({
	flexShrink: 0,
});

export interface CustomOptionType extends OptionType {
	name: string;
	prefix: string;
	strategy: string;
	subStrategy?: string[];
}

const defaultStyles: StylesConfig<CustomOptionType, true> = {
	valueContainer: (provided) => ({
		...provided,
		paddingLeft: token('space.050', '4px'),
		paddingRight: token('space.050', '4px'),
		paddingTop: token('space.050', '4px'),
		paddingBottom: token('space.050', '4px'),
		display: 'flex',
	}),
	group: (provided) => ({
		...provided,
		paddingTop: '0',
	}),
	groupHeading: (provided) => ({
		...provided,
		...h300(),
		marginTop: '0',
		marginBottom: token('space.100', '8px'),
	}),
	multiValueLabel: (provided) => ({
		...provided,
		fontWeight: 400,
		fontSize: token('font.body', '14px'),
		lineHeight: '20px',
		paddingLeft: token('space.050', '4px'),
		paddingRight: token('space.025', '2px'),
		padding: 0,
		maxWidth: '400px',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	}),
	multiValue: (provided) => ({
		...provided,
		margin: token('space.050', '4px'),
		backgroundColor: token('color.background.neutral', N20),
		'&:hover, &:focus-within': {
			backgroundColor: token('color.background.neutral.pressed', N50),
		},
		'&:hover .labels__multi-value__label span': {
			backgroundColor: token('color.background.neutral.pressed', N50),
		},
		borderRadius: '3px',
		'&:hover a, &:focus-within a': {
			textDecoration: 'none',
		},
		pointerEvents: 'auto',
		overflow: 'hidden',
		flexDirection: 'row-reverse',
		'div:hover + div': {
			backgroundColor: token('color.background.accent.red.subtler', R75),
		},
		'a > .labels__multi-value__label': {
			color: token('color.text', N800),
		},
	}),
	option: (provided) => ({
		...provided,
		width: 'max-content',
		minWidth: '100%',
	}),
	input: (provided) => ({
		...provided,
		input: {
			fontFamily: 'inherit',
			'::placeholder': {
				color: token('color.text.subtle', N200),
			},
			width: 'auto !important', // needed after upgrading react-select to a version that no longer uses react-input-autosize
		},
		maxWidth: 0,
	}),
};

const getDisabledStyles = (isDisabled): StylesConfig<CustomOptionType, true> => {
	return isDisabled
		? {
				control: (provided) => ({
					...provided,
					'&:hover, &:focus, &:active, &:focus-within': {
						borderColor: token('color.border.inverse', 'white'),
					},
					'&:focus-within, :hover': {
						borderColor: token('color.border.inverse', 'white'),
					},
					cursor: 'default !important',
					borderWidth: '0px',
				}),
				multiValueLabel: (provided) => ({
					...provided,
					paddingRight: token('space.050', '4px'),
				}),
			}
		: {};
};

const CustomControl: FC<ControlProps<CustomOptionType, true>> = ({ children, ...props }) => {
	const { isFocused } = props;
	return (
		<Control {...props}>
			{Children.map(children, (child: any) => {
				if (child?.type === CustomValueContainer) {
					return cloneElement(child, { isFocused });
				}
				return child;
			})}
		</Control>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CustomPlaceholder = styled.div({
	color: token('color.text.subtle', N200),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AddLabelPlaceholderContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
});

const AddLabelPlaceholder: FC = () => {
	return (
		<AddLabelPlaceholderContainer>
			<EditorAddIcon label="" color="currentColor" />
			<FormattedMessage {...i18n.addLabelPlaceholder} />
		</AddLabelPlaceholderContainer>
	);
};

type CustomValueContainerProps = ValueContainerProps<CustomOptionType, true> & {
	isFocused?: boolean;
};
const CustomValueContainer: FC<CustomValueContainerProps> = ({ children, isFocused, ...props }) => {
	return (
		<ValueContainer {...props}>
			{Children.map(children, (child: any) => {
				if (child?.type === CustomInput) {
					return cloneElement(child, { isFocused });
				}
				return child;
			})}
			<CustomPlaceholder>{!isFocused ? props.selectProps.placeholder : null}</CustomPlaceholder>
		</ValueContainer>
	);
};

const CustomInput: FC<InputProps<CustomOptionType, true>> = ({
	// @ts-expect-error isFocused is not provided by react-select
	isFocused,
	...props
}) => {
	const intl = useIntl();
	return (
		<Input
			{...props}
			// @ts-ignore these props are passed to an html input element which accepts placeholder
			placeholder={isFocused ? intl.formatMessage(i18n.inputPlaceholder) : undefined}
		/>
	);
};

// @atlaskit/select does not reexport the types we need: https://github.com/JedWatson/react-select/blob/master/packages/react-select/src/components/MultiValue.tsx#L92
const CustomMultiValueContainer: any = ({ children, ...props }) => {
	return (
		// @ts-ignore
		<MultiValueContainer {...props}>{Children.toArray(children).reverse()}</MultiValueContainer>
	);
};

type CustomMultiValueLabelProps = MultiValueGenericProps<CustomOptionType> & {
	spaceKey: string | undefined;
	source: string;
};
const CustomMultiValueLabel: FC<CustomMultiValueLabelProps> = ({ spaceKey, source, ...props }) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const handleClick = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'label',
				source,
				attributes: {
					privacySafePrefix: getPrivacySafePrefix(props.data.prefix),
				},
			},
		}).fire();
	}, [source, props.data.prefix, createAnalyticsEvent]);
	return (
		/* eslint-disable jsx-a11y/no-static-element-interactions */
		<div onMouseDown={(e) => e.stopPropagation()}>
			<Tooltip content={String(props.data.value).length >= 50 ? props.data.value : ''}>
				{(tooltipProps) => (
					<a
						href={`/wiki/label/${spaceKey}/${props.data.value}`}
						{...tooltipProps}
						onClick={handleClick}
					>
						{/* @ts-ignore react-select types */}
						<MultiValueLabel {...props} />
					</a>
				)}
			</Tooltip>
		</div>
	);
};

const CustomOption: FC<OptionProps<CustomOptionType, true>> = ({ children, ...props }) => {
	const {
		data: { __isNew__ },
		value,
	} = props;
	return (
		<Option {...props}>
			{__isNew__ ? (
				<CreateLabelContainer>
					<CreateLabelText>
						<FormattedMessage {...i18n.createNewLabel} />
					</CreateLabelText>
					<LabelSelectOption>{value}</LabelSelectOption>
				</CreateLabelContainer>
			) : (
				<LabelSelectOption>{value}</LabelSelectOption>
			)}
		</Option>
	);
};

type LabelsSelectComponentProps = SelectProps<CustomOptionType, true> & {
	source: string;
	spaceKey: string | undefined;
	experienceName: string;
	onUpdateSuccess?: () => void;
	isDisabled?: boolean;
	labelsQueryLoading?: boolean;
	labelsQueryError?: any;
	labelsMutationError?: any;
	validationError?: any;
	fetchRecommendedLabels?: any;
	isInDialog?: boolean;
	styles?: any;
	customProps?: any;
	controlledProps?: any;
	components: any;
	getNoOptionsMessage?: any;
};

export const LabelsSelectComponent: FC<LabelsSelectComponentProps> = forwardRef(
	(
		{
			isDisabled,
			labelsQueryLoading,
			labelsQueryError,
			labelsMutationError,
			validationError,
			fetchRecommendedLabels,
			experienceName,
			isInDialog,
			spaceKey,
			source,
			styles,
			customProps,
			controlledProps,
			components,
			getNoOptionsMessage,
		},
		ref,
	) => {
		const intl = useIntl();
		if (labelsQueryError) {
			return (
				<LabelsQueryErrorDisplayLoader experienceName={experienceName} error={labelsQueryError} />
			);
		}

		return (
			<>
				{isInDialog || !labelsQueryLoading ? (
					<div onMouseEnter={fetchRecommendedLabels}>
						<CreatableSelect<CustomOptionType, true>
							components={{
								DropdownIndicator: null,
								Placeholder: () => null,
								Control: CustomControl,
								...(!isDisabled && {
									ValueContainer: CustomValueContainer,
								}),
								Input: CustomInput,
								MultiValueContainer: CustomMultiValueContainer,
								MultiValueLabel: (props) => (
									//@ts-ignore react-select types
									<CustomMultiValueLabel spaceKey={spaceKey} source={source} {...props} />
								),
								...(isDisabled && {
									MultiValueRemove: () => null,
								}),
								Option: CustomOption,
								...components,
							}}
							ref={ref}
							noOptionsMessage={({ inputValue }) => getNoOptionsMessage(inputValue)}
							// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
							styles={mergeStyles(
								defaultStyles,
								// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
								mergeStyles(getDisabledStyles(Boolean(isDisabled)), styles || {}),
							)}
							isClearable={false}
							openMenuOnClick
							aria-label={intl.formatMessage(i18n.addLabelPlaceholder)}
							isSearchable={!isDisabled}
							closeMenuOnSelect={false}
							defaultOptions
							isMulti
							classNamePrefix="labels"
							placeholder={<AddLabelPlaceholder />}
							validationState={validationError ? 'error' : 'default'}
							{...controlledProps}
							{...customProps}
						/>
						{labelsMutationError && (
							<LabelsMutationErrorDisplayLoader error={labelsMutationError} />
						)}
						{validationError && (
							<LabelsValidationErrorLoader
								error={validationError}
								input={controlledProps.inputValue}
							/>
						)}
						{!labelsQueryLoading && <ExperienceSuccess name={experienceName} />}
						{source == 'viewPage' && <PageSegmentLoadEnd metric={VIEW_PAGE_LABELS_METRIC} />}
					</div>
				) : (
					<LabelsSelectPlaceholder />
				)}
			</>
		);
	},
);
